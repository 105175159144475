<template>
  <div v-loading="request.state.pending">
    <el-button type="primary" @click="handleCreate">Добавить</el-button>

    <services-filter style="margin: 20px 0;" />

    <services-control-buttons v-model="dialogVisible" :disabled="selectedServices.length === 0" />

    <el-table ref="tableRef" :data="items" @selection-change="handleSelect">
      <el-table-column type="selection" />
      <el-table-column width="50">
        <template #default="scope">
          <el-button
            :icon="Edit"
            type="warning"
            circle
            size="small"
            @click="handleUpdate(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="id" label="ID" width="100" />
      <el-table-column prop="shop.title" label="Магазин" width="250"/>
      <el-table-column prop="order_type_id" label="Тип" :formatter="typeFormatter" width="180" />
      <el-table-column prop="category.title" label="Категория" />
      <el-table-column prop="sku" label="Артикул" />
      <el-table-column prop="price" label="Цена" width="130" />
      <el-table-column prop="unit" label="Ед.изм." width="100" />
      <el-table-column prop="title" label="Название" />
      <el-table-column prop="is_archive" label="Архив" :formatter="archiveFormatter" width="80" />
    </el-table>

    <el-pagination
      background
      layout="prev, pager, next"
      :total="paginationState.totalCount"
      :page-size="paginationState.perPage"
      :page-count="paginationState.pageCount"
      hide-on-single-page
      style="margin-top: 20px;"
      @change="handlePagination"
    />
  </div>

  <services-control-buttons v-model="dialogVisible" :disabled="selectedServices.length === 0" />

  <el-dialog :model-value="dialogVisible === 'archive'" @update:modelValue="dialogVisible = undefined" title="Архивирование">
    <services-archive :services="selectedServices" @save="handleTableUpdated" />
  </el-dialog>

  <el-dialog :model-value="dialogVisible === 'copy'" @update:modelValue="dialogVisible = undefined" title="Копирование">
    <services-copy :services="selectedServices" @save="handleTableUpdated" />
  </el-dialog>

  <el-dialog :model-value="dialogVisible === 'edit'" @update:modelValue="dialogVisible = undefined" width="90%" title="Услуга">
    <services-form ref="formPopupRef" @save="handleSave" />
  </el-dialog>
</template>

<script lang="ts" setup>

import { useRoute, useRouter } from 'vue-router'
import { computed, nextTick, reactive, ref, watch } from 'vue'
import useDataRequest from '@/composables/useDataRequest'
import ServicesFilter from '@/components/services/ServicesFilter.vue'
import { iService } from '@/types/models'
import { findServices } from '@/api/rest/restServices'
import { iServiceFilter, iServiceFilterQuery } from '@/types/forms'
import { iRestCollectionMeta, iServicesRestItem } from '@/types/responses'
import { useStore } from '@/store'
import { Edit } from '@element-plus/icons-vue'
import ServicesForm from '@/components/services/ServicesForm.vue'
import ServicesCopy from '@/components/services/ServicesCopy.vue'
import { ElNotification } from 'element-plus'
import ServicesArchive from '@/components/services/ServicesArchive.vue'
import { iDialogVisible } from '@/components/services/types'
import ServicesControlButtons from '@/components/services/ServicesControlButtons.vue'

const route = useRoute()
const router = useRouter()
const request = useDataRequest()
const store = useStore()

const selectedServices = ref<Array<iService>>([])

const tableRef = ref()

const handleSelect = (items: iService[]) => {
  selectedServices.value = items
}

const orderTypes = computed(() => store.state.orderTypes)

const formPopupRef = ref()

const paginationState = reactive<iRestCollectionMeta>({
  pageCount: 0,
  perPage: 0,
  totalCount: 0,
  currentPage: 0
})
const items = ref<iService[]>([])

const dialogVisible = ref<iDialogVisible>(undefined)

const loadServices = () => {
  return request.tryRequest(async () => {
    const fields: Array<keyof iServiceFilter> = [
      'id',
      'title',
      'sku',
      'is_archive',
      'is_additional',
      'shop_id',
      'order_type_id',
      'category_id'
    ]

    const query: Partial<iServiceFilter> = route.query

    const filter: Partial<iServiceFilterQuery> = {}

    fields.forEach(field => {
      if (query[field]) {
        // Ключи в as ниже указаны для правильной типизации, достаточно указать только 1
        if (Array.isArray(query[field])) {
          filter[field as 'shop_id'] = { in: (query[field] as unknown as Array<string>) }
        } else {
          filter[field as 'id'] = query[field]?.toString()
        }
      }
    })

    const response = await findServices({
      filter,
      page: route.query.page ?? 1,
      'per-page': 100,
      expand: 'shop,category'
    })
    items.value = response.data.items
    Object.assign(paginationState, response.data._meta)
  })
}

const typeFormatter = (row: iServicesRestItem) => orderTypes.value.find(t => t.id === row.order_type_id)?.title
const archiveFormatter = (row: iServicesRestItem) => row.is_archive ? 'Да' : 'Нет'

const handlePagination = (page: number) => {
  router.push({ query: { ...route.query, page } })
}

const handleSave = (service: iService) => {
  const index = items.value.findIndex(i => i.id === service.id)
  if (index >= 0) {
    items.value.splice(index, 1, service)
  } else {
    items.value.unshift(service)
  }
  dialogVisible.value = undefined
}

const handleCreate = () => {
  dialogVisible.value = 'edit'
  nextTick(() => {
    formPopupRef.value.resetForm()
  })
}

const handleUpdate = (service: iService) => {
  dialogVisible.value = 'edit'
  nextTick(() => {
    formPopupRef.value.loadService(service)
  })
}

watch(() => route.query, loadServices, { immediate: true })

const handleTableUpdated = () => {
  selectedServices.value = []
  tableRef.value.clearSelection()
  dialogVisible.value = undefined
  loadServices().then(() => {
    nextTick(() => {
      ElNotification({
        title: 'Успешно',
        message: 'Операция выполнена',
        type: 'success'
      })
    })
  })
}

</script>
