<template>
  <el-table v-loading="request.state.pending" :data="tableData">
    <el-table-column type="expand">
      <template #default="scope">
        <history-data-view :data-new="scope.row.dataNew" :data-old="scope.row.dataOld" />
      </template>
    </el-table-column>
    <el-table-column prop="created_at" label="Дата" width="200" />
    <el-table-column prop="id" label="ID" width="150" />
    <el-table-column prop="number" label="Номер заявки" />
    <el-table-column prop="title" label="Действие" />
    <el-table-column prop="user" label="Сотрудник" width="300" />
  </el-table>
</template>

<script lang="ts" setup>

import useDataRequest from '@/composables/useDataRequest'
import HistoryDataView from '@/components/history/HistoryDataView.vue'
import { computed, reactive } from 'vue'
import { dateTimeDecode } from '@/utils/format'
import { iPaginationResponse } from '@/types/responses'
import { iHistory } from '@/types/models'
import { loadHistory } from '@/api/history'

const request = useDataRequest()

const state = reactive<iPaginationResponse<iHistory>>({
  items: [],
  page: 1,
  count: 0,
  pageSize: 50
})

const tableData = computed(() => state.items.map(item => ({
  dataNew: item.data,
  dataOld: item.data_old,
  created_at: dateTimeDecode(item.created_at),
  id: item.target_id,
  number: item.number,
  user: item.user.title
})))

const load = (id: number) => {
  state.items = []
  request.tryRequest(async () => {
    const response = await loadHistory({ id, model: 'Order', 'per-page': 1000 })
    Object.assign(state, response.data)
  })
}

defineExpose({
  load
})
</script>
