<template>
  <div class="dashboard-card-wrapper" v-loading="state.loading">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="8" class="mb4">
        <router-link :to="ordersUrl('danger')" class="dashboard-card danger">
          <span>Горящие</span>
          <span class="dashboard-value">
          <el-icon>
            <bell-filled />
          </el-icon>
          <span class="count">{{ state.counters.danger }}</span>
        </span>
        </router-link>
      </el-col>

      <el-col :xs="24" :sm="8" class="mb4">
        <router-link :to="ordersUrl('warning')" class="dashboard-card warning">
          <span>Просроченные</span>
          <span class="dashboard-value">
          <el-icon>
            <stopwatch />
          </el-icon>
          <span class="count">{{ state.counters.warning }}</span>
        </span>
        </router-link>
      </el-col>

      <el-col :xs="24" :sm="8" class="mb4">
        <router-link :to="ordersUrl('success')" class="dashboard-card success">
          <span>Выполненные</span>
          <span class="dashboard-value">
          <el-icon>
            <success-filled />
          </el-icon>
          <span class="count">{{ state.counters.success }}</span>
        </span>
        </router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts" setup>
import { SuccessFilled, BellFilled, Stopwatch } from '@element-plus/icons-vue'
import { computed, onUnmounted, reactive, watch } from 'vue'
import { getDashBoardCounters } from '@/api/dashboard'
import moment from 'moment'
import { makeDateFilterValue } from '@/utils/filter'
import useDataRequest from '@/composables/useDataRequest'

const props = defineProps<{
  shops: number[]
}>()

const state = reactive({
  loading: true,
  counters: {
    danger: 0,
    warning: 0,
    success: 0
  }
})

const request = useDataRequest()

type iOrdersPageMode = 'normal'|'danger'|'warning'|'success'
type iUrlParams = {
  name: string,
  query: {
    mode: iOrdersPageMode,
    shops?: string
    statuses?: string
    dateAssemble?: string
  }
}

const ordersUrl = computed(() => (mode: iOrdersPageMode) => {
  const params: iUrlParams = {
    name: 'orders',
    query: {
      mode
    }
  }

  if (props.shops.length) {
    params.query.shops = props.shops.join(',')
  }

  if (mode === 'success') {
    params.query.statuses = '1,3'
    params.query.dateAssemble = makeDateFilterValue([moment().startOf('month').toDate(), moment().endOf('month').toDate()])
  }

  return params
})

const load = () => {
  request.tryRequest(async () => {
    const response = await getDashBoardCounters(props.shops.join(','))
    Object.assign(state.counters, response.data)
    state.loading = false
  })
}

watch(() => props.shops, load, { immediate: true })

const interval = setInterval(load, 10000)

onUnmounted(() => {
  clearInterval(interval)
})

</script>

<style lang="scss">
.dashboard-card-wrapper{
  margin: 20px 0 40px;
}

.dashboard-card {
  display: block;
  color: #333;
  text-decoration: none;
  padding: 20px;
  border-radius: 4px;

  html.dark & {
    color: #fff;
  }

  &:hover {
    opacity: .8;
  }

  &.danger {
    background: rgba(234, 93, 74, .2);

    html.dark & {
      background: rgba(234, 93, 74, .5);
    }
  }

  &.warning {
    background: rgba(240, 173, 78, .2);

    html.dark & {
      background: rgba(240, 173, 78, .5);
    }
  }

  &.success {
    background: rgba(92, 184, 92, .2);

    html.dark & {
      background: rgba(92, 184, 92, .5);
    }
  }
}

.dashboard-value {
  margin-top: 20px;
  display: flex;
  align-items: center;
  font-size: 28px;

  .danger & {
    color: var(--el-color-danger)
  }

  .warning & {
    color: var(--el-color-warning)
  }

  .success & {
    color: var(--el-color-success)
  }

  .count {
    margin-left: 20px;
  }
}
</style>
