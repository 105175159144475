<template>
  <el-form
    v-loading="requestState.state.pending"
    :model="state.form"
    :rules="state.rules"
    ref="formRef"
  >
    <el-form-item prop="title" :error="requestState.state.errors.title" label="Название">
      <el-input v-model="state.form.title" />
    </el-form-item>
    <el-form-item prop="permissions" label="Разрешения">
      <el-checkbox-group v-model="state.form.permissions">
        <el-checkbox v-for="perm in permissionList" :key="perm.value" :label="perm.label" :value="perm.value" />
      </el-checkbox-group>
    </el-form-item>
    <el-form-item>
      <el-button type="success" @click="save">Сохранить</el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts" setup>
import { computed, reactive, ref } from 'vue'
import { iRole } from '@/types/models'
import { Nullable } from '@/types/forms'
import { saveRole } from '@/api/roles'
import useFormRequest from '@/composables/useFormRequest'
import { FormInstance, FormRules } from 'element-plus'
import { permissions } from '@/data/permissions'

const requestState = useFormRequest<iRole>()

const state = reactive<{
  form: Nullable<iRole>
  rules: FormRules<iRole>
}>({
  form: {
    id: null,
    title: '',
    permissions: []
  },
  rules: {
    title: [{ required: true, trigger: 'blur', message: 'Обязательно для заполнения' }]
  }
})

const load = (role: iRole) => {
  Object.assign(state.form, role)
}

const formRef = ref<FormInstance>()

const emit = defineEmits(['save'])

const permissionList = computed(() => Object.keys(permissions).map((value) => ({
  label: permissions[value as keyof typeof permissions],
  value
})))

const resetForm = () => {
  formRef.value?.resetFields()
  formRef.value?.clearValidate()
  state.form.id = null
}

const save = () => {
  formRef.value?.validate(async valid => {
    if (valid) {
      await requestState.tryRequest(async () => {
        const perms = Object.keys(permissions)
        const role = await saveRole({
          ...state.form,
          permissions: state.form.permissions?.filter(p => perms.includes(p))
        } as iRole)
        resetForm()
        emit('save', role)
      })
    }
  })
}

defineExpose({
  load,
  resetForm
})
</script>
