<template>
  <statistic-filter />

  <div v-loading="request.state.pending">
    <el-row :gutter="20">
      <el-col :xs="12" :span="8">
        <statistic-card :item="state.created" type="created" @select="handleCardClick" />
      </el-col>
      <el-col :xs="12" :span="8">
        <statistic-card :item="state.completed" type="completed" @select="handleCardClick" />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :xs="12" :span="8">
        <statistic-card :item="state.statuses" type="statuses" @select="handleCardClick" />
      </el-col>
      <el-col :xs="12" :span="8">
        <statistic-card :item="state.masters" type="masters" @select="handleCardClick" />
      </el-col>
      <el-col :xs="12" :span="8">
        <statistic-card :item="state.assemble" type="assemble" @select="handleCardClick" />
      </el-col>
    </el-row>

    <statistic-history v-if="activeHistory" :items="state[activeHistory].history" />
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive, watch } from 'vue'
import { iStatisticData } from '@/types/models'
import StatisticCard from '@/components/statistic/StatisticCard.vue'
import { useRoute, useRouter } from 'vue-router'
import StatisticFilter from '@/components/statistic/StatisticFilter.vue'
import useDataRequest from '@/composables/useDataRequest'
import { loadStatistic } from '@/api/statistic'
import { extractIds } from '@/utils/filter'
import StatisticHistory from '@/components/statistic/StatisticHistory.vue'
import moment from 'moment'

const route = useRoute()
const router = useRouter()

const state = reactive<iStatisticData>({
  created: {
    count: 0,
    summary: 0,
    history: []
  },
  completed: {
    count: 0,
    summary: 0,
    history: []
  },
  statuses: {
    count: 0,
    history: []
  },
  masters: {
    count: 0,
    history: []
  },
  assemble: {
    count: 0,
    history: []
  }
})

const activeHistory = computed<keyof iStatisticData | undefined>({
  get: () => route.query.type as keyof iStatisticData || undefined,
  set: (value) => {
    router.push({
      query: {
        ...route.query,
        type: value
      }
    })
  }
})

const handleCardClick = (type: keyof iStatisticData) => {
  activeHistory.value = type
}

const request = useDataRequest()

const load = () => {
  request.tryRequest(async () => {
    const response = await loadStatistic({
      period: route.query.period || (moment().startOf('month').format('YYYY-MM-DD') + ':' + moment().endOf('month').format('YYYY-MM-DD')),
      users: extractIds(route.query.users),
      shops: extractIds(route.query.shops)
    })
    Object.assign(state, response.data)
  })
}

watch(() => [route.query.period, route.query.users, route.query.shops].join(':'), () => {
  load()
}, { immediate: true })

</script>
