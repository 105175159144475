<template>
  <div v-if="loading">
    <div class="loader-wrapper">
      <div class="loader"></div>
    </div>
  </div>
  <login-form v-else-if="isUnauthorized"/>
  <template v-else>
    <page-header />
    <main class="page-content-wrapper">
      <router-view v-if="canAccess" />
      <forbidden-page v-else />
    </main>
  </template>
</template>

<script setup lang="ts">
import LoginForm from '@/components/LoginForm.vue'
import PageHeader from '@/components/header/PageHeader.vue'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import ForbiddenPage from '@/pages/ForbiddenPage.vue'

const store = useStore()

const isUnauthorized = computed(() => store.getters.isUnauthorized)

// Загрузка пользователя при старте приложения, если ранее был выполнен вход,
// выполняется только 1 раз при старте приложения
const loading = ref(true)
const logoutTimeout = ref<number>()

const route = useRoute()

const canAccess = computed(() => {
  const permName = (route.name as string).replaceAll('-', '_')
  return ['dashboard', 'NotFound'].includes(route.name as string) || store.getters.can(permName)
})

store.dispatch('loginByToken').then(() => {
  loading.value = false
})

// Разлогиниваем пользователя после 10 минут неактивности
window.addEventListener('mousemove', () => {
  if (logoutTimeout.value) {
    clearTimeout(logoutTimeout.value)
    logoutTimeout.value = undefined
  }
  logoutTimeout.value = setTimeout(() => {
    store.dispatch('logout')
  }, 10 * 60 * 1000)
})

</script>
