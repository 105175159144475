<template>
  <el-form label-position="top" @submit.prevent="doFilter">
    <el-row :gutter="20">
      <el-col :span="6" :xs="12">
        <el-form-item label="ID">
          <el-input v-model="state.id" clearable />
        </el-form-item>
      </el-col>

      <el-col :span="6" :xs="12">
        <el-form-item label="Магазин">
          <multiple-select v-model="state.shop_id" :options="shops" />
        </el-form-item>
      </el-col>

      <el-col :span="6" :xs="12">
        <el-form-item label="Тип">
          <multiple-select v-model="state.order_type_id" :options="orderTypes" />
        </el-form-item>
      </el-col>

      <el-col :span="6" :xs="12">
        <el-form-item label="Категория">
          <multiple-select v-model="state.category_id" :options="categories" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="6" :xs="12">
        <el-form-item label="Название">
          <el-input v-model="state.title" clearable />
        </el-form-item>
      </el-col>

      <el-col :span="6" :xs="12">
        <el-form-item label="Артикул">
          <el-input v-model="state.sku" clearable />
        </el-form-item>
      </el-col>

      <el-col :span="6" :xs="12">
        <el-form-item label="Доп">
          <el-select v-model="state.is_additional" clearable placeholder="Все">
            <el-option value="0" label="Основные" />
            <el-option value="1" label="Дополнительные" />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="6" :xs="12">
        <el-form-item label="Архив">
          <el-select v-model="state.is_archive" clearable placeholder="Все">
            <el-option value="0" label="Активные" />
            <el-option value="1" label="Архивные" />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item label="&nbsp;">
      <el-button native-type="submit" type="success">Применить</el-button>
      <el-button v-if="isFiltered" type="warning" @click="doReset">Сбросить</el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts" setup>

import { computed } from 'vue'
import { iCategory, iOrderType, iShopItem } from '@/types/models'
import { useStore } from '@/store'
import useFilter from '@/composables/useFilter'
import { iServiceFilter } from '@/types/forms'
import MultipleSelect from '@/components/MultipleSelect.vue'

const store = useStore()

const {
  state,
  doReset,
  doFilter,
  isFiltered
} = useFilter<iServiceFilter>({
  id: '',
  title: '',
  sku: '',
  is_archive: '',
  is_additional: '',
  shop_id: [],
  order_type_id: [],
  category_id: []
})

const shops = computed<Array<iShopItem>>(() => store.state.shops)
const orderTypes = computed<Array<iOrderType>>(() => store.state.orderTypes)
const categories = computed<Array<Record<string, null|string>|iCategory>>(() => {
  const nullValue = {
    order_type_id: null,
    title: 'Без категории',
    id: 'NULL'
  }

  const types: Array<iCategory> = orderTypes.value?.reduce((carry: iCategory[], item: iOrderType) => {
    item.categories.forEach(category => {
      if (carry.find(i => i.id === category.id) === undefined) {
        carry.push(category)
      }
    })
    return carry
  }, [] as Array<iCategory>)

  return [
    nullValue,
    ...types
  ]
})

</script>
