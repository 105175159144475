<template>
  <el-form inline label-position="top" @submit.prevent="doFilter">
    <el-form-item label="Дата события">
      <el-date-picker
        v-model="state.period"
        type="daterange"
        format="DD.MM.YYYY"
        value-format="YYYY-MM-DD"
        :editable="false"
        :shortcuts="pickerShortcuts"
        :clearable="false"
        unlink-panels
      />
    </el-form-item>

    <el-form-item label="ID">
      <el-input v-model="state.id" clearable style="width: 200px;" />
    </el-form-item>

    <el-form-item label="Номер">
      <el-input v-model="state.number" clearable style="width: 200px;" />
    </el-form-item>

    <el-form-item label="&nbsp;">
      <el-button native-type="submit" type="success">Применить</el-button>
      <el-button v-if="isFiltered" type="warning" @click="doReset">Сбросить</el-button>
    </el-form-item>
  </el-form>
</template>

<script lang="ts" setup>

import pickerShortcuts from '@/utils/pickerShortcuts'
import useFilter from '@/composables/useFilter'

type iFilterAttributes = {
  period: string
  id: string
  number: string
  model: string
}

const {
  state,
  doReset,
  isFiltered,
  doFilter
} = useFilter<iFilterAttributes>({
  period: '',
  id: '',
  number: '',
  model: ''
})

</script>
