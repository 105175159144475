<template>
  <p>Выбрано услуг: {{ props.services.length }}</p>

  <el-form v-loading="loading" inline>
    <el-form-item>
      <el-select v-model="value" style="width: 250px;">
        <el-option label="Отправить в архив" value="1" />
        <el-option label="Восстановить из архива" value="0" />
      </el-select>
    </el-form-item>

    <el-form-item>
      <el-button type="success" @click="handleArchive">Применить</el-button>
    </el-form-item>
  </el-form>
</template>
<script setup lang="ts">
import { iService } from '@/types/models'
import { ref } from 'vue'
import useDataRequest from '@/composables/useDataRequest'
import { updateArchive } from '@/api/rest/restServices'
import { iYiiBoolean } from '@/types/forms'
import { ElNotification } from 'element-plus'

const props = defineProps<{
  services: iService[]
}>()

const value = ref<iYiiBoolean>('1')
const loading = ref(false)

const requestWrapper = useDataRequest()

const emit = defineEmits(['save'])

const handleArchive = async () => {
  try {
    loading.value = true
    for (const service of props.services) {
      await requestWrapper.tryRequest(async () => {
        await updateArchive(service.id, value.value)
      })
    }
    emit('save')
  } catch (e) {
    ElNotification({
      title: 'Ошибка',
      message: 'Ошибка при архивировании услуг',
      type: 'error'
    })
  } finally {
    loading.value = false
  }
}

</script>
