<template>
  <el-button @click="$emit('close')">
    Закрыть
  </el-button>

  <el-form
    ref="formRef"
    label-position="top"
    :model="state.form"
    :rules="rules"
    v-loading="state.loading"
  >
    <h2 v-if="!isUpdateForm">Новый магазин</h2>
    <h2 v-else>Редактирование магазина {{ recordTitle }}</h2>

    <el-row :gutter="20">
      <el-col :xs="24" :span="4">
        <el-form-item prop="title" label="Наименование" :error="state.errors.title">
          <el-input v-model="state.form.title"/>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :span="4">
        <el-form-item prop="prefix" label="Префикс" :error="state.errors.prefix">
          <el-input v-model="state.form.prefix"/>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :span="4">
        <el-form-item prop="full_title" label="Полное наименование" :error="state.errors.full_title">
          <el-input v-model="state.form.full_title" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="24" :span="6">
        <el-form-item prop="address" label="Адрес" :error="state.errors.address">
          <el-input v-model="state.form.address" />
        </el-form-item>
      </el-col>

      <el-col :xs="24" :span="6">
        <el-form-item prop="shop_type_id" label="Тип магазина" :error="state.errors.shop_type_id">
          <el-select
            v-model="state.form.shop_type_id"
            filterable
            clearable
          >
            <el-option
              v-for="option in shopTypes"
              :key="option.id"
              :value="option.id"
              :label="option.title"
            />
          </el-select>
        </el-form-item>
      </el-col>

    </el-row>

    <el-row :gutter="20">
      <el-col :xs="12" :span="4">
        <el-form-item prop="is_auto_order_number" label="Автогенерация номера заказа">
          <el-checkbox v-model="state.form.is_auto_order_number" />
        </el-form-item>
      </el-col>

      <el-col :xs="12" :span="4">
        <el-form-item prop="is_payment_in_shop" label="Оплата на месте">
          <el-checkbox v-model="isPaymentOnSpot" />
        </el-form-item>
      </el-col>

    </el-row>

    <el-row>
      <el-col :xs="24" :span="6">
        <el-form-item label="Сотрудник">
          <el-input :model-value="userTitle" disabled/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item>
      <el-button
        type="success"
        @click="submitForm"
      >Сохранить
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { ElMessage, FormInstance, FormRules } from 'element-plus'
import { iBaseFormState, iShopForm } from '@/types/forms'
import {
  iShop, iShopType
} from '@/types/models'
import { useStore } from '@/store'
import { ValidationException } from '@/utils/exceptions'
import restShops from '@/api/rest/restShops'
import restShopTypes from '@/api/rest/restShopTypes'

type StateInterface = iBaseFormState<iShopForm>

const store = useStore()
const emit = defineEmits(['close', 'save'])
const props = defineProps<{
  recordId?: number
}>()
const formRef = ref<FormInstance>()
const loadedModelForUpdate = ref<iShop | null>(null)
const isPaymentOnSpot = ref(false)
const isUpdateForm = !!props.recordId
const userTitle = computed(() => loadedModelForUpdate.value?.createdBy?.title || store.state.user?.title || '')
const recordTitle = ref('')
const shopTypes = ref<Array<iShopType>>([])

const state = reactive<StateInterface>({
  loading: false,
  form: {
    id: null,
    title: '',
    full_title: '',
    prefix: '',
    address: '',
    is_auto_order_number: false,
    is_payment_in_shop: true,
    shop_type_id: undefined
  },
  errors: {}
})

const rules = computed<FormRules<iShop>>(() => {
  const rules = {} as FormRules<iShop>

  const fields: Array<keyof iShop> = ['title', 'prefix', 'shop_type_id']

  fields.forEach((field) => {
    rules[field] = [
      { required: true, trigger: 'blur', message: 'Обязательно' }
    ]
  })

  return rules
})

const loadForm = (recordId: number) => {
  state.loading = true

  const requestParams = {
    params: {
      expand: ['createdBy'].join(',')
    }
  }

  restShops.getById(recordId, requestParams)
    .then(response => {
      const responseModel = response.data
      loadedModelForUpdate.value = responseModel

      for (const keyTemp in state.form) {
        const key = keyTemp as keyof iShopForm

        if (!(key in responseModel)) continue

        state.form[key] = responseModel[key]
      }

      isPaymentOnSpot.value = !state.form.is_payment_in_shop
      recordTitle.value = responseModel.title
    })
    .catch(error => {
      ElMessage.error('Непредвиденная ошибка')
      console.error(error)
    })
    .finally(() => {
      state.loading = false
    })
}
const loadShopTypes = () => restShopTypes.list()
  .then(response => {
    shopTypes.value = response.data.items
  })
  .catch(error => {
    ElMessage.error('Непредвиденная ошибка')
    console.error(error)
  })
const submitForm = async () => {
  try {
    await formRef.value?.validate()
  } catch (e) {
    ElMessage({
      message: 'Не все поля заполнены',
      type: 'warning'
    })

    return
  }

  let isSuccess = true
  state.loading = true

  const form = { ...state.form }

  try {
    const response = await restShops.save(form)
    emit('save', response.data)
  } catch (e: unknown) {
    if (e instanceof ValidationException) {
      state.errors = { ...e.errors }
    } else {
      ElMessage.error('Непредвиденная ошибка')
    }
    isSuccess = false
  }

  if (isSuccess) {
    emit('close')
  }
  state.loading = false
}

watch(isPaymentOnSpot, () => {
  state.form.is_payment_in_shop = !isPaymentOnSpot.value
})

onMounted(async () => {
  state.loading = true

  await loadShopTypes()

  state.loading = false

  if (!props.recordId) return

  loadForm(props.recordId)
})

</script>
