<template>
  <el-form style="margin: 20px 0;" @submit.prevent="doFilter">
    <el-row :gutter="20">
      <el-col :span="6" :xs="24">
        <el-form-item label="Партнёр">
          <multiple-select :options="partners" v-model="state.partner_id" />
        </el-form-item>
      </el-col>
      <el-col :span="6" :xs="24">
        <el-form-item label="Магазин">
          <multiple-select :options="shops" v-model="state.shop_id" />
        </el-form-item>
      </el-col>
      <el-col :span="6" :xs="24">
        <el-form-item label="Тип работ">
          <multiple-select :options="orderTypes" v-model="state.order_type_id" />
        </el-form-item>
      </el-col>
      <el-col :span="6" :xs="24">
        <el-form-item label="&nbsp;">
          <el-button native-type="submit" type="success">Применить</el-button>
          <el-button v-if="isFiltered" type="warning" @click="doReset">Сбросить</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script lang="ts" setup>
import useFilter from '@/composables/useFilter'
import { computed } from 'vue'
import { iListItem, iOrderType, iShopItem } from '@/types/models'
import { useStore } from '@/store'
import MultipleSelect from '@/components/MultipleSelect.vue'

const store = useStore()

const {
  state,
  doReset,
  isFiltered,
  doFilter
} = useFilter({
  partner_id: [],
  order_type_id: [],
  shop_id: []
})

const orderTypes = computed<Array<iOrderType>>(() => store.state.orderTypes)
const shops = computed<Array<iShopItem>>(() => store.state.shops)
const partners = computed<Array<iListItem>>(() => store.state.partners)
</script>
