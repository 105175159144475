<template>
  <el-form
    v-loading="formRequest.state.pending"
    ref="formRef"
    :model="form"
    :rules="rules"
  >
    <el-row :gutter="20">
      <el-col :xs="24" :span="8">
        <el-form-item prop="shop_id" label="Магазин" :error="formRequest.state.errors.shop_id">
          <el-select
            v-model="form.shop_id"
            filterable
          >
            <el-option
              v-for="option in shops"
              :key="option.id"
              :value="option.id"
              :label="option.title"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :xs="24" :span="8">
        <el-form-item prop="order_type_id" label="Тип заявки" :error="formRequest.state.errors.order_type_id">
          <el-select v-model="form.order_type_id">
            <el-option v-for="type in types" :key="type.id" :value="type.id" :label="type.title" />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :xs="24" :span="8">
        <el-form-item prop="category_id" label="Категория" :error="formRequest.state.errors.category_id">
          <el-select v-model="form.category_id" clearable>
            <el-option v-for="category in categories" :key="category.id" :value="category.id" :label="category.title" />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="24" :span="8">
        <el-form-item prop="title" label="Название" :error="formRequest.state.errors.title">
          <el-input v-model="form.title" />
        </el-form-item>
      </el-col>

      <el-col :xs="24" :span="8">
        <el-form-item prop="sku" label="Артикул" :error="formRequest.state.errors.sku">
          <el-input v-model="form.sku" />
        </el-form-item>
      </el-col>

      <el-col :xs="12" :span="4">
        <el-form-item prop="unit" label="Ед. Измерения" :error="formRequest.state.errors.unit">
          <el-input v-model="form.unit" />
        </el-form-item>
      </el-col>

      <el-col :xs="12" :span="4">
        <el-form-item prop="price" label="Цена" :error="formRequest.state.errors.price">
          <el-input v-model="form.price" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="12" :span="4">
        <el-form-item prop="day_assemble_min" :error="formRequest.state.errors.day_assemble_min" label="Мин дней до сборки?">
          <el-input v-model="form.day_assemble_min" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item prop="description" label="Описание" :error="formRequest.state.errors.description">
      <el-input v-model="form.description" type="textarea" rows="10" />
    </el-form-item>

    <el-row :gutter="20">
      <el-col :xs="12" :span="4">
        <el-form-item prop="is_additional" label="Доп. услуга?">
          <el-checkbox v-model="form.is_additional" :false-value="0" :true-value="1" />
        </el-form-item>
      </el-col>

      <el-col :xs="12" :span="4">
        <el-form-item prop="is_archive" label="Архив?">
          <el-checkbox v-model="form.is_archive" :false-value="0" :true-value="1" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-button type="success" @click="handleSave">Сохранить</el-button>
  </el-form>
</template>

<script lang="ts" setup>

import { reactive, computed, ref } from 'vue'
import { iCategory, iOrderType, iService } from '@/types/models'
import { Nullable } from '@/types/forms'
import useFormRequest from '@/composables/useFormRequest'
import { useStore } from '@/store'
import { FormInstance, FormRules } from 'element-plus'
import { loadServiceById, saveService } from '@/api/rest/restServices'

const store = useStore()

const emit = defineEmits(['save'])

const formRef = ref<FormInstance>()

const form = reactive<Nullable<Record<keyof iService, string|number>>>({
  id: null,
  category_id: null,
  day_assemble_min: '0',
  description: '',
  is_additional: 0,
  is_archive: 0,
  is_computable: 1,
  order_type_id: '',
  price: '',
  service_group_id: '',
  shop_id: '',
  sku: '',
  title: '',
  unit: '',
  weight: ''
})

const rules: FormRules<iService> = {
  title: [{ required: true, trigger: 'blur', message: 'Обязательно' }],
  unit: [{ required: true, trigger: 'blur', message: 'Обязательно' }],
  price: [{ required: true, trigger: 'blur', message: 'Обязательно' }],
  sku: [{ required: true, trigger: 'blur', message: 'Обязательно' }],
  shop_id: [{ required: true, trigger: 'blur', message: 'Обязательно' }],
  order_type_id: [{ required: true, trigger: 'blur', message: 'Обязательно' }],
  category_id: [{ required: true, trigger: 'blur', message: 'Обязательно' }]
}

const formRequest = useFormRequest()

const shops = computed(() => store.state.shops)
const types = computed<Array<iOrderType>>(() => store.state.orderTypes)
const orderType = computed<iOrderType|undefined>(() => form.order_type_id ? types.value.find(t => t.id === form.order_type_id) : undefined)
const categories = computed<Array<iCategory>>(() => orderType.value?.categories ?? [])

const loadService = (service: iService) => {
  Object.assign(form, service)
}

const resetForm = () => {
  formRef.value?.resetFields()
  form.id = null
}

const handleSave = () => {
  formRef.value?.validate((valid: boolean) => {
    if (valid) {
      formRequest.tryRequest(async () => {
        const response = await saveService(form as iService)
        const itemResponse = await loadServiceById(response.data.id)
        emit('save', itemResponse.data)
      })
    }
  })
}

defineExpose({
  loadService,
  resetForm
})

</script>
