<template>
  <div v-loading="request.state.pending" class="login-wrapper">
    <div class="login-inner">
      <h4>Войдите в свой аккаунт</h4>
      <el-form
        :model="state.form"
        :rules="state.rules"
        ref="formRef"
        label-position="top"
        class="login-form"
        size="large"
        @submit.prevent="submitForm(formRef)"
      >
        <el-form-item label="Логин" :error="request.state.errors.login" prop="login">
          <el-input v-model="state.form.login"></el-input>
        </el-form-item>
        <el-form-item label="Пароль" :error="request.state.errors.password" prop="password">
          <el-input v-model="state.form.password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="&nbsp;">
          <el-button type="primary" style="width: 100%;" native-type="submit">Войти</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'
import type { FormInstance } from 'element-plus'
import { userLogin } from '@/api/auth'
import { useStore } from '@/store'
import { iBaseFormState } from '@/types/forms'
import useFormRequest from '@/composables/useFormRequest'

const store = useStore()
const formRef = ref<FormInstance>()
const request = useFormRequest()

interface FormInterface {
  password: string
  login: string
}

const state = reactive<iBaseFormState<FormInterface>>({
  form: {
    password: '',
    login: ''
  },
  rules: {
    login: [
      { required: true, trigger: 'blur', message: 'Обязательно для заполнения' }
    ],
    password: [
      { required: true, trigger: 'blur', message: 'Обязательно для заполнения' }
    ]
  }
})

const submitForm = async (formEl: FormInstance|undefined) => {
  await formEl?.validate(async (valid) => {
    if (valid) {
      await request.tryRequest(async () => {
        const response = await userLogin(state.form as FormInterface)

        await store.dispatch('loginByToken', response.data.token)
      })
    }
  })
}

</script>

<style lang="scss">
.login-wrapper {
  min-height: 100vh;
  padding: 125px 275px 0;
  box-sizing: border-box;

  .login-inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    .login-form {
      width: 50%;
    }
  }
}

@media (max-width: 767px) {
  .login-wrapper {
    padding: 20px;

    .login-inner {
      .login-form {
        width: 100%;
      }
    }
  }
}
</style>
