<template>
  <el-form v-loading="request.state.pending" :model="form" :rules="rules" label-position="top">
    <el-row v-if="isCreateForm" :gutter="20">
      <el-col :span="8" :xs="24">
        <el-form-item label="Партнер" prop="partner_id" :error="request.state.errors.partner_id">
          <el-select v-model="form.partner_id">
            <el-option v-for="item in partners" :key="item.id" :value="item.id" :label="item.title" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8" :xs="24">
        <el-form-item label="Магазин" prop="shop_id" :error="request.state.errors.shop_id">
          <el-select v-model="form.shop_id">
            <el-option v-for="item in shops" :key="item.id" :value="item.id" :label="item.title" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="8" :xs="24">
        <el-form-item label="Тип заявки" prop="order_type_id" :error="request.state.errors.order_type_id">
          <el-select v-model="form.order_type_id">
            <el-option v-for="item in availableTypes" :key="item.id" :value="item.id" :label="item.title" />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-else :gutter="20">
      <el-col :span="8" :xs="24">
        <el-form-item label="Магазин">
          {{ partners.find(p => p.id === form.partner_id)?.title || 'Не указано' }}
        </el-form-item>
      </el-col>
      <el-col :span="8" :xs="24">
        <el-form-item label="Магазин">
          {{ shops.find(p => p.id === form.shop_id)?.title || 'Не указано' }}
        </el-form-item>
      </el-col>
      <el-col :span="8" :xs="24">
        <el-form-item label="Тип заявки">
          {{ orderTypes.find(p => p.id === form.order_type_id)?.title || 'Не указано' }}
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="8" :xs="24">
        <el-form-item prop="percent_master" label="Процент мастеру" :error="request.state.errors.percent_master">
          <el-input v-model="form.percent_master" />
        </el-form-item>
      </el-col>
      <el-col :span="8" :xs="24">
        <el-form-item prop="percent_master_extra" label="Процент мастеру доп" :error="request.state.errors.percent_master_extra">
          <el-input v-model="form.percent_master_extra" />
        </el-form-item>
      </el-col>
      <el-col :span="8" :xs="24">
        <el-form-item prop="percent_shop" label="Процент магазину" :error="request.state.errors.percent_shop">
          <el-input v-model="form.percent_shop" />
        </el-form-item>
      </el-col>
      <el-col :span="8" :xs="24">
        <el-form-item prop="percent_master" label="Процент мастеру в магазине" :error="request.state.errors.percent_in_shop">
          <el-input v-model="form.percent_in_shop" />
        </el-form-item>
      </el-col>
      <el-col :span="8" :xs="24">
        <el-form-item prop="percent_in_shop_extra" label="Процент мастеру в магазине доп" :error="request.state.errors.percent_in_shop_extra">
          <el-input v-model="form.percent_in_shop_extra" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-button type="success" @click="handleSave">Сохранить</el-button>
  </el-form>
</template>

<script lang="ts" setup>

import { computed, reactive, ref, watch } from 'vue'
import { Nullable } from '@/types/forms'
import { iListItem, iOrderType, iPartnerData, iShopItem } from '@/types/models'
import { useStore } from '@/store'
import { createPartnerData, updatePartnerData } from '@/api/rest/restPartnerData'
import useFormRequest from '@/composables/useFormRequest'
import { ElNotification, FormRules } from 'element-plus'

const store = useStore()
const request = useFormRequest()

const emit = defineEmits(['save'])

const form = reactive<Nullable<iPartnerData>>({
  partner_id: null,
  shop_id: null,
  order_type_id: null,
  percent_in_shop: 0,
  percent_in_shop_extra: 0,
  percent_master: 0,
  percent_master_extra: 0,
  percent_shop: 0,
  is_hidden: false
})

const isCreateForm = ref(false)

const orderTypes = computed<Array<iOrderType>>(() => store.state.orderTypes)
const shops = computed<Array<iShopItem>>(() => store.state.shops)
const partners = computed<Array<iListItem>>(() => store.state.partners)

const usedTypes = computed<Array<number>>(() => store.state.percents
  .filter(p => p.shop_id === form.shop_id && p.partner_id === form.partner_id)
  .map(p => p.order_type_id)
)
const availableTypes = computed<Array<iOrderType>>(() => store.state.orderTypes.filter(ot => !usedTypes.value.includes(ot.id)))

const updateItem = (item: iPartnerData) => {
  isCreateForm.value = false
  Object.assign(form, item)
}

const rules: FormRules<iPartnerData> = {
  partner_id: [{ required: true, message: 'Обязательно для заполнения', trigger: 'blur' }],
  percent_master: [{ required: true, message: 'Обязательно для заполнения', trigger: 'blur' }],
  percent_master_extra: [{ required: true, message: 'Обязательно для заполнения', trigger: 'blur' }],
  percent_in_shop: [{ required: true, message: 'Обязательно для заполнения', trigger: 'blur' }],
  percent_in_shop_extra: [{ required: true, message: 'Обязательно для заполнения', trigger: 'blur' }],
  percent_shop: [{ required: true, message: 'Обязательно для заполнения', trigger: 'blur' }]
}

const createItem = () => {
  isCreateForm.value = true
  Object.assign(form, {
    partner_id: null,
    shop_id: null,
    order_type_id: null,
    percent_in_shop: 0,
    percent_in_shop_extra: 0,
    percent_master: 0,
    percent_master_extra: 0,
    percent_shop: 0,
    is_hidden: false
  })
}

const handleSave = () => {
  request.tryRequest(async () => {
    const result = isCreateForm.value ? await createPartnerData(form) : await updatePartnerData(form)
    store.commit('addPercent', result.data)

    ElNotification({
      title: 'Успешно',
      message: 'Проценты сохранены',
      type: 'success'
    })

    emit('save', result.data)
  })
}

defineExpose({
  updateItem,
  createItem
})

watch(() => [form.partner_id, form.shop_id], () => {
  if (isCreateForm.value) {
    form.order_type_id = null
  }
})

</script>
