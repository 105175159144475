<template>
  <el-form style="margin: 20px 0;" @submit.prevent="doFilter">
    <el-row :gutter="20">
      <el-col :span="16" :xs="24">
        <el-form-item prop="title" label="Название">
          <el-input v-model="state.title" clearable />
        </el-form-item>
      </el-col>
      <el-col :span="8" :xs="24">
        <el-form-item prop="status" label="Статус">
          <el-select v-model="state.status" clearable>
            <el-option v-for="status in statusOptions" :key="status.value" :label="status.label" :value="status.value" />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8" :xs="24">
        <el-form-item prop="dateStart" label="Дата постановки">
          <el-date-picker
            v-model="state.dateStart"
            type="daterange"
            format="DD.MM.YYYY"
            value-format="YYYY-MM-DD"
            :editable="false"
            :shortcuts="pickerShortcuts"
            unlink-panels
          />
        </el-form-item>
      </el-col>
      <el-col :span="8" :xs="24">
        <el-form-item prop="dateExpected" label="Дата завершения">
          <el-date-picker
            v-model="state.dateExpected"
            type="daterange"
            format="DD.MM.YYYY"
            value-format="YYYY-MM-DD"
            :editable="false"
            :shortcuts="pickerShortcuts"
            unlink-panels
          />
        </el-form-item>
      </el-col>
      <el-col :span="8" :xs="24">
        <el-form-item prop="dateEnd" label="Фактическая дата завершения">
          <el-date-picker
            v-model="state.dateEnd"
            type="daterange"
            format="DD.MM.YYYY"
            value-format="YYYY-MM-DD"
            :editable="false"
            :shortcuts="pickerShortcuts"
            unlink-panels
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12" :xs="24">
        <el-form-item prop="executor" label="Исполнитель">
          <el-input v-model="state.executor" clearable />
        </el-form-item>
      </el-col>
      <el-col :span="12" :xs="24">
        <el-form-item prop="user" label="Автор">
          <el-input v-model="state.user" clearable />
        </el-form-item>
      </el-col>
    </el-row>
    <el-button type="success" native-type="submit">Применить</el-button>
    <el-button v-if="isFiltered" type="warning" @click="doReset">Сбросить</el-button>
  </el-form>
</template>

<script lang="ts" setup>

import { computed } from 'vue'

import taskStatusMap from '@/data/taskStatusMap'
import pickerShortcuts from '@/utils/pickerShortcuts'
import { iTasksFilter } from '@/types/forms'
import useFilter from '@/composables/useFilter'

const {
  state,
  doFilter,
  doReset,
  isFiltered
} = useFilter<iTasksFilter>({
  title: '',
  status: '',
  dateStart: '',
  dateExpected: '',
  dateEnd: '',
  user: '',
  executor: ''
})

const statusOptions = computed(() => Object.keys(taskStatusMap).map(value => {
  const item = taskStatusMap[parseInt(value)]
  return {
    label: item.text,
    value
  }
}))
</script>
