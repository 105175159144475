type iTaskStatusMapItem = {
  text: string
  type: string
}

const taskStatusMap: Record<number, iTaskStatusMapItem> = {
  1: {
    text: 'Новая',
    type: 'info'
  },
  2: {
    text: 'В работе',
    type: ''
  },
  3: {
    text: 'Завершена',
    type: 'success'
  }
}

export default taskStatusMap
