<template>
  <el-button @click="$emit('close')">
    Закрыть
  </el-button>

  <el-form
    ref="formRef"
    label-position="top"
    :model="state.form"
    :rules="rules"
    v-loading="state.loading"
  >
    <h2 v-if="!isUpdateForm">Новая мастер</h2>
    <h2 v-else>Редактирование мастера {{ recordTitle }}</h2>

    <el-row :gutter="20">
      <el-col :xs="24" :span="6">
        <el-form-item prop="title" label="Имя" :error="state.errors.title">
          <el-input v-model="state.form.title"/>
        </el-form-item>
      </el-col>

      <el-col :xs="24" :span="6">
        <el-form-item prop="phone" label="Телефон" :error="state.errors.phone">
          <el-input v-model="state.form.phone" :formatter="formatPhone"
                    :parser="(value) => '+7' + phoneMask.unmasked(value)"/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="24" :span="6">
        <el-form-item prop="location_id" label="Локация" :error="state.errors.location_id">
          <el-select
            v-model="state.form.location_id"
            filterable
            clearable
          >
            <el-option
              v-for="option in locations"
              :key="option.id"
              :value="option.id"
              :label="option.title"
            />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :xs="24" :span="6">
        <el-form-item prop="category_ids" label="Категории услуг" :error="state.errors.category_ids">
          <el-select
            v-model="state.form.category_ids"
            filterable
            clearable
            multiple
          >
            <el-option
              v-for="option in categories"
              :key="option.id"
              :value="option.id"
              :label="option.title"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="24" :span="6">
        <el-form-item prop="phone2" label="Телефон 2" :error="state.errors.phone2">
          <el-input v-model="state.form.phone2" :formatter="formatPhone"
                    :parser="(value) => (value ? '+7' : '') + phoneMask.unmasked(value)"/>
        </el-form-item>
      </el-col>

      <el-col :xs="24" :span="6">
        <el-form-item prop="email" label="E-mail" :error="state.errors.email">
          <el-input v-model="state.form.email"/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="24" :span="6">
        <el-form-item prop="password" label="Пароль" :error="state.errors.password">
          <el-input v-model="state.form.password"/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="24" :span="6">
        <el-form-item prop="passport_series" label="Серия" :error="state.errors.passport_series">
          <el-input v-model="state.form.passport_series" :formatter="(value) => passportSeriesMask.masked(value)"
                    :parser="(value) => passportSeriesMask.unmasked(value)"/>
        </el-form-item>
      </el-col>

      <el-col :xs="24" :span="6">
        <el-form-item prop="passport_number" label="Оплата" :error="state.errors.passport_number">
          <el-input v-model="state.form.passport_number" :formatter="(value) => passportNumberMask.masked(value)"
                    :parser="(value) => passportNumberMask.unmasked(value)"/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="24" :span="6">
        <el-form-item prop="passport_date" label="Дата оформления" :error="state.errors.passport_date">
          <el-date-picker
            v-model="state.form.passport_date"
            format="DD.MM.YYYY"
            :editable="false"
            :disabled-date="(data) => currentMoment.toDate() <= data"
            clearable
            value-format="YYYY-MM-DD"
          />
        </el-form-item>
      </el-col>

      <el-col :xs="24" :span="6">
        <el-form-item prop="passport_issued_by" label="Примечания" :error="state.errors.passport_issued_by">
          <el-input v-model="state.form.passport_issued_by" type="textarea" :rows="4"/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="24" :span="6">
        <el-form-item prop="passportScans" label="Файлы">
          <el-upload
            ref="uploadRef"
            v-model:file-list="state.form.passportScans"
            :action="uploadAction"
            :headers="uploadHeaders"
            multiple
            :auto-upload="false"
            :on-success="handleUploadSuccess"
            :before-remove="beforeRemove"
          >
            <el-button type="primary">Добавить файлы</el-button>
          </el-upload>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="24" :span="6">
        <el-form-item prop="partner_id" label="Партнер" :error="state.errors.partner_id">
          <el-select
            v-model="state.form.partner_id"
            filterable
          >
            <el-option
              v-for="option in partners"
              :key="option.id"
              :value="option.id"
              :label="option.title"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <template v-if="state.form.id">
      <el-row :gutter="20">
        <el-col :xs="24" :span="12">
          <master-map
            ref="mapRef"
            :settings="mapCenter ? { location: { center: mapCenter } } : null"
            :polygons-coordinates="polygonsCoordinates"
          />
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :xs="24" :span="12">
          <el-form-item>
            <el-button
              type="primary"
              plain
              @click="() => state.form.map = JSON.stringify(mapRef.newPolygonCoords)"
            >Зафиксировать зону
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :xs="24" :span="12">
          <el-form-item prop="map" :error="state.errors.map">
            <el-input v-model="state.form.map" type="textarea" :rows="4"/>
          </el-form-item>
        </el-col>
      </el-row>
    </template>

    <el-row :gutter="20">
      <el-col :xs="24" :span="5">
        <el-form-item prop="has_push" label="Слать уведомления по оплате?">
          <el-checkbox v-model="state.form.has_push"/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :xs="12" :span="2">
        <el-form-item prop="is_thursday" label="Четверг">
          <el-checkbox v-model="state.form.is_thursday"/>
        </el-form-item>
      </el-col>

      <el-col :xs="12" :span="2">
        <el-form-item prop="is_wednesday" label="Среда">
          <el-checkbox v-model="state.form.is_wednesday"/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item>
      <el-button
        type="success"
        @click="submitForm"
      >Сохранить
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, reactive, ref } from 'vue'
import {
  ElMessage,
  ElMessageBox,
  FormInstance,
  FormRules, UploadFile,
  UploadInstance,
  UploadUserFile
} from 'element-plus'
import { iBaseFormState, iMasterForm } from '@/types/forms'
import {
  iCategory,
  iLocation, iPartner
} from '@/types/models'
import { ValidationException } from '@/utils/exceptions'
import restLocations from '@/api/rest/restLocations'
import { formatPhone } from '@/utils/format'
import restServiceCategories from '@/api/rest/restServiceCategories'
import { useStore } from '@/store'
import restPartners from '@/api/rest/restPartners'
import { passportNumberMask, passportSeriesMask, phoneMask } from '@/utils/maska'
import restMasters, { masterUrls } from '@/api/rest/restMasters'
import moment from 'moment/moment'
import restInstance from '@/api/rest/instance'
import MasterMap from '@/components/masters/MasterMap.vue'
import { LngLat } from '@yandex/ymaps3-types/common/types/lng-lat'

type iLocalMasterForm = iMasterForm & {
  passportScans?: UploadUserFile[]
}
type StateInterface = iBaseFormState<iLocalMasterForm>

const emit = defineEmits(['close', 'save'])
const props = defineProps<{
  recordId?: number
}>()
const store = useStore()
const formRef = ref<FormInstance>()
const isUpdateForm = !!props.recordId
const recordTitle = ref('')
const locations = ref<iLocation[]>([])
const categories = ref<iCategory[]>([])
const partners = ref<iPartner[]>([])
const uploadRef = ref<UploadInstance>()
const uploadAction = ref('')
const currentMoment = moment()
const polygonsCoordinates = ref<LngLat[][][] | null>(null)
const mapRef = ref()
const mapCenter = ref<LngLat | null>()

const state = reactive<StateInterface>({
  loading: false,
  form: {
    id: null,
    title: '',
    phone: '',
    phone2: '',
    email: '',
    password: '',
    passport_series: '',
    passport_number: '',
    passport_date: '',
    passport_issued_by: '',
    partner_id: null,
    has_push: false,
    is_thursday: false,
    is_wednesday: false,
    category_ids: [],
    passportScans: [],
    location_id: null,
    map: ''
  },
  errors: {}
})

const uploadHeaders = computed(() => ({ 'X-Api-Key': store.state.token }))
const rules = computed<FormRules<iLocalMasterForm>>(() => {
  const rules = {} as FormRules<iLocalMasterForm>
  const phonePatternRule = { pattern: /^\+7\d{10}$/, trigger: 'blur', message: 'Укажите телефон полностью' }
  const requiredBlurRule = { required: true, trigger: 'blur', message: 'Обязательно' }
  const fields: Array<keyof iLocalMasterForm> = ['title', 'location_id', 'category_ids', 'partner_id', 'password']

  fields.forEach((field) => {
    rules[field] = [
      requiredBlurRule
    ]
  })

  rules.phone = [
    phonePatternRule,
    requiredBlurRule
  ]
  rules.phone2 = [
    phonePatternRule
  ]

  return rules
})

const loadForm = (recordId: number) => {
  state.loading = true

  restMasters.getById(recordId, {
    params: {
      fields: ['*', 'categories.id'].join(','),
      expand: ['categories', 'passport', 'location'].join(',')
    }
  })
    .then(response => {
      const responseModel = response.data

      for (const keyTemp in state.form) {
        const key = keyTemp as keyof iMasterForm

        if (!(key in responseModel)) continue

        state.form[key] = responseModel[key]
      }

      state.form.passportScans = response.data.passport.map(file => ({ name: file.title, id: file.id }))
      state.form.category_ids = response.data.categories.map(item => item.id)
      recordTitle.value = responseModel.title

      if (responseModel.map) {
        polygonsCoordinates.value = [JSON.parse(responseModel.map)]
      }

      if (responseModel.location && responseModel.location.lng && responseModel.location.lat) {
        mapCenter.value = [responseModel.location.lng, responseModel.location.lat]
      }
    })
    .catch(error => {
      ElMessage.error('Непредвиденная ошибка')
      console.error(error)
    })
    .finally(() => {
      state.loading = false
    })
}
const submitForm = async () => {
  try {
    await formRef.value?.validate()
  } catch (e) {
    ElMessage({
      message: 'Не все поля заполнены',
      type: 'warning'
    })

    return
  }

  let isSuccess = true
  state.loading = true

  const form = { ...state.form }
  delete form.passportScans

  try {
    const response = await restMasters.save(form)
    uploadAction.value = restInstance.defaults.baseURL + masterUrls.savePassport(response.data.id)
    await nextTick(() => uploadRef.value?.submit())
    emit('save', response.data)
  } catch (e: unknown) {
    if (e instanceof ValidationException) {
      state.errors = { ...e.errors }
    } else {
      ElMessage.error('Непредвиденная ошибка')
      console.error(e)
    }
    isSuccess = false
  }

  if (isSuccess) {
    emit('close')
  }
  state.loading = false
}
const loadLocations = () => restLocations.list({ params: { fields: ['id', 'title'].join(',') } })
  .then(response => {
    locations.value = response.data.items
  })
  .catch(error => {
    ElMessage.error('Непредвиденная ошибка')
    console.error(error)
  })
const loadCategories = () => restServiceCategories.list({ params: { fields: ['id', 'title'].join(',') } })
  .then(response => {
    categories.value = response.data.items
  })
  .catch(error => {
    ElMessage.error('Непредвиденная ошибка')
    console.error(error)
  })
const loadPartners = () => restPartners.list({ params: { fields: ['id', 'title'].join(',') } })
  .then(response => {
    partners.value = response.data.items
  })
  .catch(error => {
    ElMessage.error('Непредвиденная ошибка')
    console.error(error)
  })
const handleUploadSuccess = (response: Response) => {
  if (response.status < 200 || response.status >= 300) {
    ElMessage.warning('Не удалось загрузить скан паспорта')
  }
}
const beforeRemove = (uploadFile: UploadFile & { id: number }) => {
  if (!uploadFile.id) {
    return true
  }

  return ElMessageBox.confirm(
    `Подтвердите удаления файла ${uploadFile.name} ?`
  ).then(
    async () => {
      if (!state.form.id) {
        ElMessage.error('Непредвиденная ошибка')
        return false
      }

      state.loading = true

      try {
        await restMasters.passport.delete(state.form.id, uploadFile.id)
        return true
      } catch (e: unknown) {
        ElMessage.error('Непредвиденная ошибка')
        console.error(e)
        return false
      } finally {
        state.loading = false
      }
    },
    () => false
  )
}

onMounted(async () => {
  state.loading = true

  await loadLocations()
  await loadCategories()
  await loadPartners()

  state.loading = false

  if (!props.recordId) return

  loadForm(props.recordId)
})

</script>
