import instance, { fileInstance } from '@/api/instance'
import { AxiosPromise } from 'axios'
import { iOrdersIndex } from '@/types/responses'
import {
  iCustomerSuggestion,
  iFieldSet,
  iFieldSetForm, iOrderView,
  iShopSettings
} from '@/types/models'
import { iOrderFormFull, iOrderSellerForm } from '@/types/forms'

export const loadIndex = (params: object): AxiosPromise<iOrdersIndex> => instance.get('orders/index', { params })
export const saveOrder = (order: iOrderFormFull | iOrderSellerForm): AxiosPromise<iOrderView> => instance.post('orders/save', order)
export const setActiveFieldSet = (id: number): AxiosPromise<void> => instance.get('orders/use-field-set', { params: { id } })
export const saveFieldSet = (fieldSet: iFieldSetForm): AxiosPromise<iFieldSet> => instance.post('orders/field-set', fieldSet, { params: { id: fieldSet.id } })
export const deleteFieldSet = (id: number): AxiosPromise<iFieldSet> => instance.delete('orders/field-set', { params: { id } })
export const downloadExport = (params: object): AxiosPromise<Blob> => fileInstance.get('orders/export', { params })
export const getShopSettings = (shopId: number): AxiosPromise<iShopSettings> => instance.get('orders/get-shop-settings', { params: { shopId } })
export const getCustomerSuggestions = (term: string): AxiosPromise<iCustomerSuggestion[]> => instance.get('orders/customer-suggestions', { params: { term } })
