<template>
  <el-form v-loading="request.state.pending">
    <el-row :gutter="20">
      <el-col :xs="24" :span="8">
        <el-form-item label="Пользователь">
          <multiple-select :options="users" v-model="userFilter" />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :span="8">
        <el-form-item label="Магазин">
          <multiple-select :options="shops" v-model="shopsFilter" />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :span="8">
        <el-form-item label="Период">
          <el-date-picker
            v-model="periodFilter"
            type="daterange"
            format="DD.MM.YYYY"
            :editable="false"
            :shortcuts="pickerShortcuts"
            :clearable="false"
            unlink-panels
          />
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script lang="ts" setup>

import { computed, ref } from 'vue'
import { extractDateValue, extractIds, makeDateFilterValue } from '@/utils/filter'
import { useRoute, useRouter } from 'vue-router'
import MultipleSelect from '@/components/MultipleSelect.vue'
import pickerShortcuts from '@/utils/pickerShortcuts'
import { iListItem, iShopItem } from '@/types/models'
import { useStore } from '@/store'
import useDataRequest from '@/composables/useDataRequest'
import { loadUsersForStatistic } from '@/api/statistic'
import moment from 'moment/moment'

const route = useRoute()
const router = useRouter()
const store = useStore()

const request = useDataRequest()

const shops = computed<Array<iShopItem>>(() => store.state.shops)
const users = ref<iListItem[]>()

const doFilter = (query: object) => {
  router.push({
    query: {
      ...route.query,
      ...query
    }
  })
}

const shopsFilter = computed<number[]>({
  get: () => extractIds(route.query.shops),
  set: (value) => {
    doFilter({ shops: value.join(',') })
  }
})

const userFilter = computed<number[]>({
  get: () => extractIds(route.query.users),
  set: (value) => {
    doFilter({ users: value })
  }
})

const periodFilter = computed({
  get: () => extractDateValue(route.query.period, [moment().startOf('month').toDate(), moment().endOf('month').toDate()]),
  set: (value) => {
    doFilter({ period: makeDateFilterValue(value) })
  }
})

request.tryRequest(async () => {
  const response = await loadUsersForStatistic()
  users.value = response.data
})
</script>
