<template>
  <el-row :gutter="20" v-loading="formRequest.state.pending">
    <el-col :span="12" :xs="24">

      <h2>Новая заявка</h2>

      <el-form
        ref="formRef"
        label-position="top"
        :model="state.form"
        :rules="state.rules"
      >
        <el-row :gutter="20" v-loading="requestServices.state.pending">
          <el-col :span="6" :xs="24">
            <el-form-item prop="shop_id" label="Магазин" :error="formRequest.state.errors.shop_id">
              <el-select
                v-model="state.form.shop_id"
                filterable
                :disabled="shops.length === 1"
              >
                <el-option
                  v-for="option in shops"
                  :key="option.id"
                  :value="option.id"
                  :label="option.title"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6" :xs="24">
            <el-form-item prop="type_id" label="Тип заявки" :error="formRequest.state.errors.type_id">
              <el-select v-model="state.form.type_id" :disabled="types.length === 1 || checkDisabledChain('shop_id')" no-data-text="Выберите магазин">
                <el-option v-for="type in types" :key="type.id" :value="type.id" :label="type.title" />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6" :xs="24">
            <el-form-item prop="category_id" label="Категория" :error="formRequest.state.errors.category_id">
              <el-select v-model="state.form.category_id" clearable no-data-text="Выберите магазин и тип заявки" :disabled="checkDisabledChain('type_id')">
                <el-option v-for="category in categories" :key="category.value" :value="category.value" :label="category.label" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="6" :xs="24">
            <el-form-item prop="customer" label="Клиент (ФИО)" :error="formRequest.state.errors.customer">
              <el-autocomplete
                v-model="state.form.customer"
                :disabled="checkDisabledChain('category_id')"
                :fetch-suggestions="queryCustomer"
                @select="handleCustomerSelect"
              />
            </el-form-item>
          </el-col>

          <el-col :span="6" :xs="24">
            <el-form-item prop="phone" label="Телефон" :error="formRequest.state.errors.phone">
              <el-input v-model="state.form.phone" :disabled="checkDisabledChain('customer')" />
            </el-form-item>
          </el-col>

          <el-col :span="6" :xs="24">
            <el-form-item prop="phone2" label="Телефон 2" :error="formRequest.state.errors.phone2">
              <el-input v-model="state.form.phone2" :disabled="checkDisabledChain('phone')" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="6" :xs="24">
            <el-form-item prop="address_city" label="Город" :error="formRequest.state.errors.address_city">
              <el-input v-model="state.form.address_city" :disabled="checkDisabledChain('phone')" />
            </el-form-item>
          </el-col>

          <el-col :span="6" :xs="24">
            <el-form-item prop="address_district" label="Район" :error="formRequest.state.errors.address_district">
              <el-input v-model="state.form.address_district" :disabled="checkDisabledChain('address_city')" />
            </el-form-item>
          </el-col>

          <el-col :span="6" :xs="24">
            <el-form-item prop="address_street" label="Улица" :error="formRequest.state.errors.address_street">
              <el-input v-model="state.form.address_street" :disabled="checkDisabledChain('address_city')" />
            </el-form-item>
          </el-col>

          <el-col :span="2" :xs="8">
            <el-form-item prop="address_house" label="Дом" :error="formRequest.state.errors.address_house">
              <el-input v-model="state.form.address_house" :disabled="checkDisabledChain('address_street')" />
            </el-form-item>
          </el-col>

          <el-col :span="2" :xs="8">
            <el-form-item prop="address_building" label="Корпус" :error="formRequest.state.errors.address_building">
              <el-input v-model="state.form.address_building" :disabled="checkDisabledChain('address_house')" />
            </el-form-item>
          </el-col>

          <el-col :span="2" :xs="8">
            <el-form-item prop="address_room" label="Квартира" :error="formRequest.state.errors.address_room">
              <el-input v-model="state.form.address_room" :disabled="checkDisabledChain('address_house')" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12" :xs="24">
            <el-form-item prop="address_extra" label="Адрес доп" :error="formRequest.state.errors.address_extra">
              <el-input v-model="state.form.address_extra" :disabled="checkDisabledChain('address_room')" />
            </el-form-item>
          </el-col>

          <el-col :span="6" :xs="24">
            <el-form-item prop="salesman" label="Продавец" :error="formRequest.state.errors.salesman">
              <el-input v-model="state.form.salesman" :disabled="checkDisabledChain('address_room')" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item prop="note" label="Примечания" :error="formRequest.state.errors.note">
          <el-input v-model="state.form.note" type="textarea" :disabled="checkDisabledChain('salesman')" />
        </el-form-item>

        <el-form-item label="Файлы">
          <order-file-upload v-model="state.form.files" :disabled="checkDisabledChain('salesman')" />
        </el-form-item>

        <template v-if="state.form.services?.length">
          <order-summary-table :items="tableServices" />
          <p>Итоговая стоимость: {{ totalCost }}</p>
        </template>

        <el-form-item class="hidden-xs-only">
          <el-button type="success" @click="submitForm">Сохранить</el-button>
        </el-form-item>

      </el-form>

    </el-col>

    <el-col :span="12" :xs="24">
      <div v-loading="requestServices.state.pending">
        <h2>Услуги</h2>

        <service-table
          v-if="state.form.salesman"
          v-model="state.form.services"
          :shop-id="state.form.shop_id"
          :order-type-id="state.form.type_id"
          :category-id="state.form.category_id"
          :services="state.services"
          simple-filters
        />

        <p v-else>Заполните обязательные поля</p>
      </div>
    </el-col>
  </el-row>

  <el-form-item class="hidden-sm-and-up">
    <el-button type="success" @click="submitForm">Сохранить</el-button>
  </el-form-item>
</template>

<script setup lang="ts">
import { computed, ComputedRef, nextTick, reactive, ref, watch } from 'vue'
import {
  iOrderService,
  iOrderType,
  iService, iShopItem, iShopSettings
} from '@/types/models'
import useDataRequest from '@/composables/useDataRequest'
import { loadServicesByShopId } from '@/api/services'
import { getShopSettings, saveOrder } from '@/api/orders'
import ServiceTable from '@/components/orders/ServiceTable.vue'
import useFormRequest from '@/composables/useFormRequest'
import { ElMessage, FormInstance } from 'element-plus'
import router from '@/router'
import { useStore } from '@/store'
import { iBaseFormState, iOrderSellerForm } from '@/types/forms'
import useCustomerAutocomplete from '@/composables/useCustomerAutocomplete'
import OrderFileUpload from '@/components/orders/OrderFileUpload.vue'
import OrderSummaryTable from '@/components/orders/OrderSummaryTable.vue'
import { formatCurrency } from '@/utils/format'

interface StateInterface extends iBaseFormState <iOrderSellerForm> {
  services: Array<iService>
  shopSettings: iShopSettings
}

const request = useDataRequest()
const formRequest = useFormRequest<iOrderSellerForm>()
const store = useStore()
const formRef = ref<FormInstance>()

const requestServices = useDataRequest()

const shops = computed(() => store.state.shops)

const requiredField = [{ required: true, trigger: 'blur', message: 'Обязательно для заполнения' }]

const state = reactive<StateInterface>({
  shopSettings: {
    expectedNumber: null
  },
  form: {
    type_id: null,
    category_id: null,
    shop_id: shops.value.length === 1 ? shops.value[0].id : null,
    files: [],
    phone: '',
    phone2: '',
    salesman: '',
    customer: '',
    address_building: '',
    address_city: '',
    address_district: '',
    address_extra: '',
    address_house: '',
    address_room: '',
    address_street: '',
    services: [],
    note: ''
  },
  rules: {
    shop_id: requiredField,
    type_id: requiredField,
    category_id: requiredField,
    customer: requiredField,
    phone: requiredField,
    address_city: requiredField,
    address_street: requiredField,
    address_house: requiredField,
    address_room: requiredField,
    salesman: requiredField
  },
  services: []
})

const shop: ComputedRef<iShopItem|undefined> = computed<iShopItem|undefined>(() => shops.value.find(s => s.id === state.form.shop_id))
const types = computed<Array<iOrderType>>(() => store.state.orderTypes.filter(t => shop.value?.orderTypes.includes(t.id)))

const fillChain = Object.keys(state.rules as object)
const checkDisabledChain = computed(() => (field: keyof iOrderSellerForm) => fillChain.slice(0, fillChain.indexOf(field) + 1).reduce((carry, item) => {
  if (!state.form[item as keyof iOrderSellerForm]) {
    return true
  }
  return carry
}, false))

const categories = computed(() => {
  let result: Record<string, string | number>[] = []
  if (types.value.length) {
    const typeObj = types.value.find(i => i.id === state.form.type_id)

    const filteredServices = state.form.type_id === null
      ? state.services
      : state.services.filter(service => service.order_type_id === state.form.type_id)

    if (filteredServices.find(service => service.category_id === null)) {
      result.push({ value: -1, label: 'Без категории' })
    }

    const serviceCategories = filteredServices.map(i => i.category_id)

    result = result.concat(typeObj?.categories.filter(i => serviceCategories.includes(i.id)).map(i => ({
      value: i.id,
      label: i.title
    })) || [])
  }

  return result
})

const { queryCustomer, handleCustomerSelect } = useCustomerAutocomplete(state.form)

const submitForm = async () => {
  formRef.value?.validate(async valid => {
    if (valid) {
      const success = await formRequest.tryRequest(async () => {
        await saveOrder(state.form as iOrderSellerForm)
      })

      if (success) {
        return await router.push({
          name: 'orders'
        })
      }
    }

    ElMessage({
      message: 'Не все поля заполнены',
      type: 'warning'
    })
  })
}

const loadServices = async () => {
  state.services = []
  state.form.services = []

  if (state.form.shop_id !== null) {
    await requestServices.tryRequest(async () => {
      state.services = (await loadServicesByShopId({ shopId: state.form.shop_id as number })).data || []
    })

    await request.tryRequest(async () => {
      state.shopSettings = (await getShopSettings(state.form.shop_id as number)).data || {
        expectedNumber: null
      }
    })
  }

  await nextTick(() => {
    state.form.type_id = types.value.length === 1 ? types.value[0].id : null
    state.form.category_id = categories.value.length === 1 ? categories.value[0].id as number : null
  })
}

const tableServices = computed(() => state.form.services?.map<Partial<iService> & iOrderService>((item) => {
  const service = state.services.find(i => i.id === item.service_id)
  return {
    ...service,
    ...item
  }
}))

const totalCost = computed(() => formatCurrency(state.form.services?.reduce((carry, service) => carry + (service.price * (service.qty || 0)), 0) || 0))

watch(() => state.form.shop_id, loadServices, { immediate: true })
</script>
