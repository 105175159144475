<template>
  <partner-data-filter />
  <el-button type="primary" @click="handleCreate">Добавить</el-button>
  <partner-data-table @update="handleUpdate" />
  <el-dialog v-model="isFormVisible">
    <partner-data-form ref="formRef" @save="handleSave" />
  </el-dialog>
</template>

<script lang="ts" setup>
import PartnerDataFilter from '@/components/partnersData/PartnerDataFilter.vue'
import PartnerDataTable from '@/components/partnersData/PartnerDataTable.vue'
import { nextTick, ref } from 'vue'
import PartnerDataForm from '@/components/partnersData/PartnerDataForm.vue'
import { iPartnerData } from '@/types/models'

const isFormVisible = ref(false)
const formRef = ref()

const handleUpdate = (item: iPartnerData) => {
  isFormVisible.value = true
  nextTick(() => {
    formRef.value.updateItem(item)
  })
}

const handleCreate = () => {
  isFormVisible.value = true
  nextTick(() => {
    formRef.value.createItem()
  })
}

const handleSave = () => {
  isFormVisible.value = false
}
</script>
