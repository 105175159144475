import instance from '@/api/rest/instance'
import { AxiosPromise } from 'axios'
import { iService } from '@/types/models'
import { iYiiBoolean, Nullable } from '@/types/forms'
import { iRestCollection } from '@/types/responses'

export const findServices = (params = {}): AxiosPromise<iRestCollection<iService>> => instance.get('services', { params: { ...params, paginate: '1' } })
export const saveService = (params: Nullable<iService>): AxiosPromise<iService> => params.id
  ? instance.put('services/' + params.id, params)
  : instance.post('services?expand=shop,category', params)

export const updateArchive = (id: number, value: iYiiBoolean) => instance.patch(`services/${id}`, { is_archive: value })

export const loadServiceById = (id: number): AxiosPromise<iService> => instance.get('services/' + id, { params: { expand: 'shop,category' } })
