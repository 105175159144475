import instance from '@/api/rest/instance'
import { AxiosPromise } from 'axios'
import { iPartner } from '@/types/models'
import { iRestCollection, iRestCollectionList } from '@/types/responses'
import { Nullable } from '@/types/forms'

export default {
  get: (params = {}): AxiosPromise<iRestCollection<iPartner>> => instance.get('partners', params),
  getById: (id: number, params = {}): AxiosPromise<iPartner> => instance.get('partners/' + id, params),
  list: (params = {}): AxiosPromise<iRestCollectionList<iPartner>> => instance.get('partners/list', params),
  save: (params: Nullable<iPartner>) => {
    if (params.id) return instance.put('partners/' + params.id, params)
    else return instance.post('partners', params)
  },
  remove: (id: number) => instance.delete('partners/' + id)
}
