<template>
  <div v-loading="state.loading">
    <dashboard-history-item
      v-for="chunk in chunks"
      :key="chunk.date"
      :title="chunk.title"
      :items="chunk.items"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive, watch } from 'vue'
import { getHistory } from '@/api/dashboard'
import { iHistory } from '@/types/models'
import DashboardHistoryItem from '@/components/dashboard/DashboardHistoryItem.vue'
import makeChunks from '@/utils/makeChunks'
import useDataRequest from '@/composables/useDataRequest'

const props = defineProps<{
  number: string
}>()

const state = reactive<{
  data: iHistory[],
  loading: boolean
}>({
  data: [],
  loading: true
})

const request = useDataRequest()

const load = () => {
  request.tryRequest(async () => {
    const response = await getHistory(props.number)
    state.data = response.data
    state.loading = false
  })
}

const chunks = computed(() => makeChunks(state.data))

watch(() => props.number, load, { immediate: true })
</script>
