import { createApp } from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'

import ElementPlus from 'element-plus'
import locale from 'element-plus/es/locale/lang/ru'

import 'element-plus/theme-chalk/display.css'
import './styles/index.scss'

import 'dayjs/locale/ru.js'
import { createYmaps } from 'vue-yandex-maps'
import moment from 'moment'

moment.locale('ru')

createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus, { locale })
  .use(createYmaps({
    apikey: '347e9bd1-9938-43ee-8e6f-d9ffe05d5ec8',
    lang: 'ru_RU'
  }))
  .mount('#app')
