import { computed, reactive, watch } from 'vue'
import { LocationQuery, useRoute, useRouter } from 'vue-router'

const useFilter = <T extends LocationQuery>(filter: T) => {
  const route = useRoute()
  const router = useRouter()

  const defaultValues = { ...filter }

  const state = reactive<LocationQuery>(filter)
  const filteredValues = reactive<LocationQuery>({})

  watch(() => route.query, () => {
    Object.keys(state).forEach((key) => {
      state[key] = route.query[key] ?? defaultValues[key]
    })
    Object.assign(filteredValues, state)
  }, { immediate: true })

  const doFilter = async () => {
    await router.push({ query: state as LocationQuery })
  }

  const doReset = async () => {
    await router.push({ query: {} })
  }

  const isFiltered = computed(() => Object.keys(filteredValues).filter(key => Array.isArray(defaultValues[key])
    ? ((Array.isArray(filteredValues[key]) ? filteredValues[key] : [filteredValues[key]]) as Array<string>).filter(f => !(defaultValues[key] as Array<string>).includes(f.toString())).length
    : defaultValues[key] !== filteredValues[key]).length > 0)

  return {
    state,
    isFiltered,
    doFilter,
    doReset
  }
}

export default useFilter
