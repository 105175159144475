import { getCustomerSuggestions } from '@/api/orders'
import { ElNotification } from 'element-plus'
import { iCustomerSuggestion } from '@/types/models'

type iCustomerSuggestionItem = {
  value: string
  item: iCustomerSuggestion
}

const useCustomerAutocomplete = (form: object) => {
  const queryCustomer = (queryString: string, cb: (result: iCustomerSuggestionItem[]) => void) => {
    const emptyValue: iCustomerSuggestionItem[] = []
    if (queryString.length > 0) {
      getCustomerSuggestions(queryString).then(result => {
        const response = result.data
        const items: iCustomerSuggestionItem[] = response.map(item => ({
          value: `${item.customer} [${item.phone}]`,
          item
        }))
        cb(items)
      }).catch(() => {
        ElNotification({
          title: 'Ошибка',
          message: 'Ошибка при загрузке списка заказчиков',
          type: 'error'
        })
        cb(emptyValue)
      })
    } else {
      cb(emptyValue)
    }
  }

  const handleCustomerSelect = (item: iCustomerSuggestionItem) => {
    Object.assign(form, item.item)
  }

  return {
    queryCustomer,
    handleCustomerSelect
  }
}

export default useCustomerAutocomplete
