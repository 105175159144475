<template>
  <div class="m20">
    <el-button type="primary" :disabled="props.disabled" @click="emit('update:modelValue', 'copy')">Копирование</el-button>
    <el-button type="danger" :disabled="props.disabled" @click="emit('update:modelValue', 'archive')">Архивирование</el-button>
  </div>
</template>

<script lang="ts" setup>

import { iDialogVisible } from '@/components/services/types'

const props = defineProps<{
  disabled?: boolean
  modelValue: iDialogVisible
}>()

const emit = defineEmits(['update:modelValue'])

</script>
