<template>
  <header class="header">
    <div class="hidden-sm-and-up">
      <div class="header-content">
        <div class="logo">
          <router-link to="/">
            <img alt="Белая кисть" src="@/assets/logo-mobile.png" style="max-width: 100%">
          </router-link>
        </div>
        <div class="right-part">
          <div class="user-menu">
           <span class="user-button">
             <span class="user-title">{{ userTitle }}</span>
             <span class="role-title">{{ roleTitle }}</span>
           </span>
          </div>
        </div>
        <el-button :icon="More" @click="toggleMobileMenu" size="large" type="primary" />
      </div>
    </div>
    <div class="hidden-xs-only">
      <div class="header-content">
        <div class="logo">
          <router-link to="/">
            <img alt="Белая кисть" src="@/assets/logo.png" width="400" height="40">
          </router-link>
        </div>
        <div class="right-part">
          <theme-switch />
          <div class="user-menu">
            <el-popover
              placement="bottom-end"
              trigger="hover"
            >
              <template #reference>
                <span class="user-button">
                  <span class="user-title">{{ userTitle }}</span>
                  <span class="role-title">{{ roleTitle }}</span>
                </span>
              </template>
              <el-link @click="logout">
                <el-icon><SwitchButton /></el-icon>
                Выйти
              </el-link>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="header-menu">
        <header-menu />
      </div>
    </div>
  </header>

  <div class="header-fix" />

  <expand-transition>
    <div class="hidden-sm-and-up menu-bg" v-show="isMobileMenuVisible">
      <div class="mobile-menu-item">
        <theme-switch />
      </div>
      <header-menu is-vertical />
      <div class="mobile-menu-item">
        <el-link @click="logout" class="menu-logout-button">Выйти</el-link>
      </div>
    </div>
  </expand-transition>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useStore } from '@/store'
import { SwitchButton, More } from '@element-plus/icons-vue'
import HeaderMenu from '@/components/header/HeaderMenu.vue'
import ThemeSwitch from '@/components/header/ThemeSwitch.vue'
import { useRoute } from 'vue-router'
import ExpandTransition from '@/components/ExpandTransition.vue'

const store = useStore()
const route = useRoute()

const isMobileMenuVisible = ref(false)

const toggleMobileMenu = () => {
  isMobileMenuVisible.value = !isMobileMenuVisible.value
  window.scrollTo(0, 0)
}

const userTitle = computed(() => store.state.user?.title || '')
const roleTitle = computed(() => store.state.role?.title || 'Без роли')
const logout = () => {
  store.dispatch('logout')
}

watch(() => route.fullPath, () => { isMobileMenuVisible.value = false })
</script>

<style lang="scss">
.right-part {
  display: flex;
  align-items: center;

  .user-menu {
    margin-left: 15px;
    background: #3B393C;
    color: #fff;
  }
  .el-switch {
    --el-switch-off-color: #C4C4C4;
    .el-icon {
      color: #c4c4c4;
    }

    .el-switch__label.is-active {
      .el-icon {
        color: var(--el-color-primary);
      }
    }
  }
}

.mobile-menu-item {
  padding-left: var(--el-menu-level-padding);
}

.menu-bg {
  background: var(--el-menu-bg-color);
}

.el-link.menu-logout-button {
  line-height: var(--el-menu-item-height);
  display: block;
}
</style>
